import fetch from '../../api/fetch';
import wait from '../../util/wait';
import { document } from '../../globals';

interface ActiveExperimentResponse {
    hasActiveExperiment: boolean;
    message?: string;
}

export default class OptimizelyWebRequest {
    public static async initOptimizelyWeb() {
        try {
            const response = await Promise.race([
                OptimizelyWebRequest.requestActiveExperiment(),
                wait(2000),
            ]);

            // meaning only when we get false on hasActiveExperiment we do not load in
            if (!response || response.hasActiveExperiment) {
                OptimizelyWebRequest.loadDelayedScripts();
            }
        } catch {
            OptimizelyWebRequest.loadDelayedScripts();
        }
    }

    private static loadDelayedScripts(): void {
        if (!document) {
            return;
        }

        const script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.optimizely.com/js/24558580927.js');
        document.head.appendChild(script);
    }

    private static async requestActiveExperiment() {
        const response = await fetch('ajax/abtest/web/hasActiveExperiment', {
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        });

        return await response.json() as Promise<ActiveExperimentResponse>;
    }
}
