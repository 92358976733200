import { ConsentServiceFactory } from '@yoursurprise/segment-analytics';
import fetch from '../../api/fetch';

const wasTrackingConsentGranted = async (): Promise<boolean> => {
    try {
        return await ConsentServiceFactory.create('consent', fetch).wasConsentGranted();
    } catch (error) {
        return false;
    }
};

export default wasTrackingConsentGranted;
