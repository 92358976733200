import { document } from '../../globals';
import OptimizelyWebRequest from './OptimizelyWebRequest';
import wasTrackingConsentGranted from '../../analytics/AnalyticsService/Consent';
import wait from '../../util/wait';
import ErrorLogger from '../../error-logger/ErrorLogger';

Promise.race([
    wasTrackingConsentGranted(),
    wait(2000),
]).then((response) => {
    // if timeout we should listen on consentGranted because visitor might not have consent
    if (!response) {
        document?.addEventListener('consentGranted', () => {
            OptimizelyWebRequest.initOptimizelyWeb().catch(() => null);
        }, { once: true });
    } else {
        OptimizelyWebRequest.initOptimizelyWeb().catch(() => null);
    }
}).catch((e: Error) => ErrorLogger.createFromGlobals()?.log(e));
